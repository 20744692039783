import React from "react"

export default () => {
  return (
    <section className="section is-sml">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered" id="anchor-content">
            <h1 className="f-h1">
              Words to me, you; <span className="is-red-pale">us</span>.
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}
